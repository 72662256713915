import clsx from "clsx";

function RadioInput(props){
    return (
        <div className={clsx(
            "flex items-center",
            props.className
        )}
        onClick={props.onSwitch}>
            <input
            type="radio"
            className="hidden"
            name={props.name}
            value={props.value ? props.value : props.label}
            checked={props.checked}/>
            <div
            className={clsx(
                "w-[1rem] aspect-square rounded-full",
                props.checked ? "bg-surprizz-dark-blue" : "bg-surprizz-cream"
            )}></div>
            <label className="font-bold text-[0.625rem] text-surprizz-dark-blue ml-2">{props.label}</label>
        </div>
    )
}

export default RadioInput;