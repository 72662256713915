import clsx from "clsx";
import { useEffect, useRef } from "react";
function Button(props){
    const ref = useRef();
    useEffect(()=>{
        ref.current.addEventListener("touchstart",(e)=>{
            e.target.closest("button").style.backgroundColor = props.color
            e.target.closest("button").style.color = props.background
        })
        ref.current.addEventListener("touchend",(e)=>{
            e.target.closest("button").style.backgroundColor = props.background
            e.target.closest("button").style.color = props.color
        })
    });
    return(
        <button
        style={{
            background: props.background,
            color: props.color
        }}
        className={clsx(
            "font-bold text-[0.75rem] flex items-center px-4 py-2 rounded-3xl w-fit transition-all",
            props.className,

        )}
        onClick={()=>{
            props.onClick()
        }}
        ref={ref}
        >
            <span>{props.label}</span>
            {props.icon && <img src="/images/icons/button_icon.svg" alt="icon" className="block ml-2"/>}
        </button>
    )
}

export default Button;