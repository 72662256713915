import { useEffect, useRef, useState } from "react";
import Page from "../../Page";
import RadioInputSection from "../../inputs/RadioInput/RadioInputSection";
import { getSurveySections } from "../../../data/survey";
import CreamButton from "../../buttons/CreamButton";
import gsap from "gsap/gsap-core";
import BlueBlock from "../../blocks/BlueBlock";
import clsx from "clsx";
import Question from "../Questions/Question";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(useGSAP);

function Survey(props) {
    return (
        <div className="min-w-full px-5 flex flex-col items-center">
            {props.sections.map(
                v => <RadioInputSection
                    title={v.title}
                    options={v.options}
                    onSwitch={v.onSwitch} />
            )}
            <CreamButton
                label="Valider les réponses"
                onClick={props.onSubmit} />
        </div>
    )
}

function Diner(props) {
    const navigate = useNavigate();
    const ref = useRef();
    const [firstAnswer, setFirstAnswer] = useState(null);
    const [secondAnswer, setSecondAnswer] = useState(null);
    const scroller = useRef();
    const [allow, updateAllow] = useState(false);
    const [survey, updateSurvey] = useState(true);
    const [sections, setSections] = useState(getSurveySections(setFirstAnswer, setSecondAnswer));
    const [cookie,setCookie,removeCookie] = useCookies(["activities"]);
    const pageContainer = useRef();

    function checkAnswers() {
        if (!firstAnswer || !secondAnswer || firstAnswer != "Toi mon Apollon" || secondAnswer != "Je suis amoureuse de toi") {
            updateAllow(false)
        } else {
            updateAllow(true);
        }
    }

    useEffect(()=>{
        if(!cookie.activities || !cookie.activities.diner){
            navigate("/home")
        }
    },[]);

    useGSAP(()=>{
        if(cookie.activities && cookie.activities.gifts){
            gsap.to(pageContainer.current,{
                duration:0.5,
                xPercent: -100,
                onComplete : ()=>{
                    navigate("/diner/award ")
                }
            })
        }
    },[]);

    return (
        <div ref={pageContainer}>
            <Page
            title="Diner"
            subtitle="Cette fois je t’emmène dans un endroit plus chill, moins amazing. En revanche, je dois quand même savoir tes envies (sucré ou salé) et si tu as beaucoup faim ou non !"
            content={
                <div className={clsx(
                    "w-full h-full overflow-x-hidden",
                    !survey ? "overflow-y-hidden" : ""
                )} ref={scroller}>
                    <div className="w-full h-full flex" ref={ref}>
                        <Survey
                            sections={sections}
                            onSubmit={() => {
                                checkAnswers();
                                scroller.current.scroll({
                                    top: 0,
                                    behavior: 'smooth',
                                });
                                gsap.to(ref.current, {
                                    duration:0.5,
                                    xPercent: -100,
                                    onComplete: () => {
                                        scroller.current.scroll({
                                            top: 0,
                                        });
                                        updateSurvey(false);
                                    }
                                })
                            }}
                        />
                        <div className="min-w-full h-fit flex justify-center items-center">
                            <BlueBlock
                                title={allow ? "Content" : "Pas content"}
                                subtitle={allow ? ["Aha bonnes réponse(s) !!"] : ["Tu vas voir à mal répondre aux questions !!!"]}
                                texts={[]}
                                hideAvatar
                                image={allow ? "images/memes/logan.png" : "images/memes/kirby.jpg"}
                                label={allow ? "Continuer" : "Retour"}
                                onClick={() => {
                                    if (allow) {
                                        gsap.to(ref.current, {
                                            duration:0.5,
                                            xPercent: -200
                                        })
                                    } else {
                                        gsap.to(ref.current, {
                                            duration:0.5,
                                            xPercent: 0,
                                            onComplete: () => {
                                                updateSurvey(true);
                                            }
                                        })
                                    }
                                }} />
                        </div>
                        <div className="min-w-full h-fit flex justify-center items-center">
                            <Question
                                onFail={props.onFail}
                                onEnd={() => {
                                    setCookie("activities",{...cookie.activities, "gifts":true});
                                    gsap.to(ref.current, {
                                        duration:0.5,
                                        xPercent: -300
                                    });
                                }}
                            />
                        </div>
                        <div className="min-w-full h-fit flex justify-center items-center">
                            <BlueBlock
                                title="Bravo !!"
                                texts={[
                                    
                                ]}
                                label="Découvrir le diner"
                                image="images/memes/homer.png"
                                onClick={() => {
                                    gsap.to(pageContainer.current, {
                                        duration:0.5,
                                        xPercent: -100,
                                        onComplete: () => {
                                            navigate("/diner/award")
                                        }
                                    })
                                }} 
                                hideAvatar/>
                        </div>
                    </div>
                </div>
            }
            animate={true} />
        </div>
    )
}

export default Diner;