const colors = {
    "light-blue":"#9ECCFA",
    "dark-blue":"#0B1957",
    "cream":"#FFEBC5",
    "grey":"#696969"
}

function getColor(color){
    return colors[color];
}

export {getColor};