import clsx from "clsx";
import { getColor } from "../../../../../data/colors";
import SummaryOption from "../../SummaryOption";

function ExternalOption(props){
    return (
        <SummaryOption
        label={props.label}
        background={getColor("cream")}
        icon="chevron_right"
        color="#000000"
        className={clsx(
            props.className
        )}
        invert={true}
        onClick={props.onClick}
/>
    )
}

export default ExternalOption;