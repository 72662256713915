import { useRef } from "react";
import Page from "../../Page";
import BlueBlock from "../../blocks/BlueBlock";
import { useNavigate } from "react-router-dom";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

function Help(){
    const ref = useRef();
    const navigate = useNavigate();
    useGSAP(()=>{
        gsap.from(ref.current,{
            duration: 0.5,
            xPercent: 100
        })
    },[])
    return (
        <div ref={ref}>
            <Page
            title="Assistance"
            subtitle="Aha chef tu te débrouilles tu crois quoi non mais oh je vais pas tout faire pour toi quand même !!"
            content={<div>
                <BlueBlock
                texts={[]}
                hideAvatar
                image="/images/memes/laugh.gif"
                label="Retour au programme"
                onClick={()=>{
                    gsap.to(ref.current,{
                        duration:0.5,
                        xPercent:-100,
                        onComplete : ()=>{
                            navigate("/home");
                        }
                    })
                }}/>
                </div>}/>
        </div>
    )
}

export default Help;