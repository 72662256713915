import clsx from "clsx";

function WhiteBlock(props){
    return (
        <div
        className="w-block bg-white rounded-3xl"
        style={{
            height: props.height ? `${props.height}px` : ""
        }}>
            {props.content}
        </div>
    )
}

export default WhiteBlock;