import { useEffect, useRef } from "react";
import Questions from "../Questions";
import gsap from "gsap/gsap-core";
import BlueBlock from "../../blocks/BlueBlock";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

function VictoryScreen(){
    const navigate = useNavigate();
    const ref = useRef();
    return (
        <div className="flex items-center justify-center w-full h-full" ref={ref}>
            <BlueBlock
            title="Bravo !!"
            texts={[
                "Félicitations, tu as accompli ta tâche et tu peux découvrir ce qui t’attend pour la suite de ta journée !"
            ]}
            label="Découvrir"
            image="images/memes/homer.png"
            onClick={()=>{
                gsap.to(ref.current,{
                    duration:0.5,
                    xPercent:-100,
                    onComplete : ()=>{
                        navigate("/prepare/award")
                    }
                })
            }}/>
        </div>
    )
}

function Prepare(props){
    const [cookie,setCookie,removeCookie] = useCookies(["activities"])
    const ref = useRef();
    const navigate = useNavigate();
    useEffect(()=>{
        if(cookie["activities"] && cookie["activities"]["diner"]){
            gsap.to(ref.current,{
                duration:0.5,
                xPercent:-100
            })
        } else if(!cookie["activities"] || !cookie.activities.prepare){
            navigate("/home");
        }
    },[]);
    return(
        <div className="w-full h-full overflow-x-hidden overflow-y-visible">
            <div className="w-full h-full flex" ref={ref}>
                <div className="min-w-full h-full">
                    <Questions
                    title="Pour se préparer"
                    subtitle="Bien bon on a bien marché, on s’est bien dépensés. Maintenant il faut se désaltérer alors encore un petit effort intellectuel pour un gosier tout frais !"
                    onFail={props.lock}
                    onEnd={()=>{
                        setCookie("activities",{...cookie["activities"], "diner":true})
                        gsap.to(ref.current,{
                            duration:0.5,
                            xPercent:-100
                        })
                    }}
                    questions={[
                        {
                            "question": "Comment appelle-t-on le médecin pour les pieds ?",
                            "answer": "PODOLOGUE"
                        }
                    ]}/>
                </div>
                <div className="min-w-full h-full">
                    <VictoryScreen/>
                </div>
            </div>
        </div>
    )
}

export default Prepare;