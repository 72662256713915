import { useGSAP } from "@gsap/react"
import { getColor } from "../../../data/colors"
import CreamButton from "../../buttons/CreamButton"
import Paragraph from "../../texts/Paragraph"
import Title from "../../texts/Title"
import gsap from "gsap/gsap-core"
import { useRef } from "react"

gsap.registerPlugin(useGSAP);

function BlueBlock(props){
    const ref = useRef();
    return (
       <div className="flex flex-col items-center">
            {!props.hideAvatar && <img src="/images/BlueBlock/animoji.svg" className="relative z-40 max-w-[150px]" ref={ref} alt="Animoji Logan"/>}
            <div className="w-block bg-surprizz-dark-blue flex flex-col items-center rounded-3xl px-6 py-7 relative z-50">
                {props.title && <Title
                color={getColor("light-blue")}
                text={props.title}
                className="mb-2"/>}
                {props.subtitle && <Paragraph
                text={props.subtitle}
                color="#FFFFFF"
                className="font-semibold mb-3 text-center"/>}
                {props.image && <figure className="mb-2">
                    <img src={props.image} alt={props.title} width={250} className="aspect-video object-cover"/></figure>}
                {props.texts.map(text=><Paragraph
                text={text}
                color="#FFFFFF"
                className="font-semibold my-2"/>)}
                <CreamButton
                label={props.label}
                icon={true}
                className="mt-5"
                onClick={props.onClick}/>
            </div>
       </div>
    )
}

export default BlueBlock