import { useGSAP } from "@gsap/react";
import WhiteBlock from "../blocks/WhiteBlock";
import gsap from "gsap/gsap-core";
import { useEffect, useRef, useState } from "react";
import { getCode } from "../../data/code";

gsap.registerPlugin(useGSAP);

function FillCode(props){
    var blocks = [];
    for(let i = 0; i < props.size; i++){
        blocks.push(
            <div className="w-[1rem] h-[1rem] rounded-full bg-surprizz-light-grey mx-2"></div>
        )
    }

    return(
        <>
        {blocks.map(
            v=>v
        )}
        </>
    )
}

function CurrentCode(props){
    const ref = useRef();
    useEffect(()=>{
        if(props.code.length>=4 && props.code!=getCode()){
            let timeline = gsap.timeline({
                delay: 0.25,
                repeat: 1,
                onComplete: ()=>{
                    gsap.to(ref.current,{
                        duration: 0.1,
                        x: 0,
                        onComplete: ()=>{
                            props.resetCode();
                        }
                    })
                    
                }
            });
            timeline.to(ref.current,{
                duration:0.1,
                x:-50,
            })
            timeline.to(ref.current,{
                duration:0.1,
                x:50,
            })
        }
    })
    return (
        <div className="flex items-center" ref={ref}>
            {props.code.split("").map(
                v=>
                <div className="w-[1rem] h-[1rem] mx-2 relative">
                    <span className="text-[1.5rem] font-bold absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">{v}</span>
                </div>
            )}
            {props.code.length<4 && <FillCode size={4-props.code.length}/>}
        </div>
    )
}

function CodeBoxNumbers(props){
    var options = [];
    for(let i=1;i<=9;i++){
        options.push(
            <div className="w-[30%] mx-[1.5%] aspect-square relative">
                <div className="w-[50px] aspect-square absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-surprizz-light-grey rounded-full active:bg-surprizz-light-blue"
                onClick={()=>{
                    props.updateCode(i.toString())
                }}>
                    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-[1.5rem] font-semibold">{i}</div>
                </div>
            </div>
        )
    }

    return(
        <>
        {options.map(
            v=>v
        )}
        <div className="w-[30%] mx-[1.5%] aspect-square relative">
                <div className="w-[50px] aspect-square absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-surprizz-light-grey rounded-full"
                onClick={()=>{
                    props.updateCode("0")
                }}>
                    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-[1.5rem] font-semibold">0</div>
                </div>
        </div>
        </>
    )
}

function CodeBox(props){
    const ref = useRef();
    const [code,updateCode] = useState("");

    useGSAP(()=>{
        gsap.from(ref.current,{
            delay : 0.5,
            scale: 0,
            duration: 0.5
        })
    },[]);

    useEffect(()=>{
        if(code==getCode()){
            gsap.to(ref.current,{
                duration: 0.5,
                y:"-100vh",
                onComplete : ()=>{
                    props.unlock();
                }
            })
        }
    },[code])

    function changeCode(number){
        if(code.length<4){
            updateCode(code+number);
        }
    }

    return (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" ref={ref}>
            <WhiteBlock
            content={
                <div className="flex flex-col items-center py-5">
                    <div className="text-surprizz-dark-blue font-bold mb-3">Entrez le mot de passe</div>
                    <CurrentCode
                    code={code}
                    resetCode={()=>{
                        updateCode("");
                    }}/>
                    <div className="w-[225px] flex justify-center flex-wrap mt-10">
                        <CodeBoxNumbers
                        updateCode={(char)=>{
                            changeCode(char);
                        }}/>
                    </div>
                </div>
            }
            />
        </div>
    )
}

function BlockScreen(props){
    const ref = useRef();

    useGSAP(()=>{
        gsap.from(ref.current,{
            duration:0.5,
            opacity: 0
        })
    },[])
    return (
        <div
        className="w-dvw h-dvh fixed top-0 left-0 z-50 backdrop-blur"
        ref={ref}>
            <CodeBox
            unlock={()=>{
                gsap.to(ref.current,{
                    duration: 0.5,
                    opacity: 0,
                    onComplete : ()=>{
                        props.unlock();
                    }
                })
            }}/>
        </div>
    )
}

export default BlockScreen;