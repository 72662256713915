import clsx from "clsx";
import { getColor } from "../../../data/colors";

function Paragraph(props){
    return (
        <p
        style={{
            color : props.color ? props.color : getColor("dark-blue")
        }}
        className={
            clsx(
                "leading-5 text-[0.875rem]",
                props.className
            )
        }>{props.text}</p>
    )
}

export default Paragraph;