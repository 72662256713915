import logo from './logo.svg';
import './App.css';
import { getColor } from './data/colors';
import { Routes, Route } from "react-router-dom";
import Homepage from './components/pages/Homepage';
import Summary from './components/pages/Summary';
import Footer from './components/Footer';
import Lunch from './components/pages/Lunch';
import Activity from './components/pages/Activity';
import BlockScreen from './components/BlockScreen';
import { CookiesProvider, useCookies } from 'react-cookie'
import Diner from './components/pages/Diner';
import Award from './components/Award';
import Surprizz from './components/pages/Surprizz';
import Prepare from './components/pages/Prepare';
import Gifts from './components/pages/Gifts/index.js';
import Help from './components/pages/Help/index.js';
import { useState, useRef } from 'react';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(useGSAP);

function App() {
  const [cookie, setCookie, removeCookie] = useCookies(["block"]);
  const [footer,setFooter] = useState(true);
  const footerContainer = useRef();

  function lock() {
    setCookie('block', true);
  }

  useGSAP(()=>{
    if(footer){
      gsap.set(footerContainer.current,{
        display: "block",
      });
      gsap.from(footerContainer.current,{
        duration: 0.5,
        yPercent:100
      });
    } 
  },[footer]);


  return (
    <>
      <div
        className='w-screen h-dvh'
        style={{
          background: `url('images/main_bg.svg') ${getColor("light-blue")}`,
          backgroundSize: "cover",
          backgroundPosition: "center"
        }}>
        <div className='w-full h-full relative flex flex-col overflow-hidden'>
          <div className='w-full grow overflow-x-hidden overflow-y-scroll'>
            <Routes>
              <Route
                path='/'
                element={
                  <Homepage />
                } />
              <Route
                path='/home'
                element={
                  <Summary />
                } />
              <Route
                path='/repas'
                element={
                  <Lunch
                    lock={lock}
                  />
                } />
              <Route
                path='/repas/award'
                element={
                  <Award
                    title="La Belleza"
                    image="/images/activities/belleza.png"
                    texts={[]}
                    label="Découvrir la carte"
                    url="https://menu.bigmammagroup.com/labellezza"
                    unlockCondition="activity"
                  />
                } />
              <Route
                path='/activite'
                element={
                  <Activity
                    onFail={lock} />
                } />
              <Route
                path='/activite/award'
                element={
                  <Award
                    title="Tourisme"
                    subtitle="Voilà notre activité du jour !! Prêt pour un peu de marche ! Montre moi tes qualités de randonneur !"
                    image="/images/activities/belgium.png"
                    texts={[]}
                    label="Retourner à l'accueil"
                    unlockCondition="surprizz"
                  />
                } />
              <Route
                path='/surprizz'
                element={
                  <Surprizz
                    onFail={lock} />
                } />
              <Route
                path='/surprizz/award'
                element={
                  <Award
                    title="TEL UN VIKING"
                    image="/images/activities/axe.png"
                    texts={[]}
                    label="Découvrir"
                    url="https://woodcutter.world/fr"
                    unlockCondition="prepare"
                  />
                } />
              <Route
                path='/diner'
                element={
                  <Diner
                    onFail={lock} />
                } />
              <Route
                path='/diner/award'
                element={
                  <Award
                    title="Anata"
                    subtitle="Pour finir la soirée en beauté mais oui je sais tu le savais !"
                    image="/images/activities/anata.png"
                    texts={[]}
                    label="Découvrir la carte"
                    url="https://restaurant-anata.be/fr/menu/9189636363358568448"
                    unlockCondition="activity"
                  />
                } />
              <Route
                path='/prepare'
                element={
                  <Prepare
                    lock={lock}
                  />
                } />
              <Route
                path='/prepare/award'
                element={
                  <Award
                    title="LET’S GO BAR"
                    subtitle="Evidemment qu’on va voir Ed et on va aller boire un petit verre pour fêter ça !"
                    image="/images/memes/drunk_baby.png"
                    texts={[]}
                    label="Retour à l'accueil"
                    unlockCondition="diner"
                  />
                } />
              <Route
                path='/cadeaux'
                element={<Gifts 
                setFooter = {setFooter}/>}
              />
              <Route
                path='/cadeaux/award'
                element={
                  <Award
                    title="Bravo !!"
                    image="/images/memes/santa.png"
                    texts={["Félicitations, tu as accompli ta tâche et tu peux découvrir ce qui t’attend pour la suite de ta journée ! "]}
                    label="Demander mes cadeaux"
                    url="https://menu.bigmammagroup.com/labellezza"
                    unlockCondition="finished"
                  />
                } />
              <Route
                path='/end'
                element={
                  <Award
                    hideImage
                    title="It's the end !!"
                    image="/images/memes/au_revoir.png"
                    texts={["J’espère que ce support numérique t’as plu tout au long de la journée ! Je te souhaite encore un excellent anniversaire !"]}
                    label="Fin de la journée"
                    unlockCondition="finished"
                  />
                } />
              <Route
                path='/help'
                element={<Help />} />
            </Routes>
          </div>
          <div ref={footerContainer}>
            {footer && <Footer/>}
          </div>
        </div>
      </div>
      <CookiesProvider>
        {cookie.block && <BlockScreen
          unlock={() => {
            removeCookie('block');
          }} />}
      </CookiesProvider>
    </>
  );
}

export default App;
