const activities = [
    setActivity("Repas","meal","/repas", true),
    setActivity("Activité","activity","/activite"),
    setActivity("Surprizz","surprizz","/surprizz"),
    setActivity("Pour se préparer","prepare","/prepare"),
    setActivity("Diner","diner","/diner"),
    setActivity("Cadeaux","gifts","/cadeaux")
]

const activityName = "Tourisme";

const surprizzName = "Lancer de hache";

function getActivityName(){
    return activityName.toUpperCase();
}

function getSurprizzName(){
    return surprizzName.toUpperCase();
}

function setActivity(name,cookieName,link, unrestricted = false){
    return {
        "name":name,
        "cookieName":cookieName,
        "link":link,
        "unrestricted" : unrestricted
    }
}

function getActivities(){
    return activities;
}

export {getActivities, getActivityName, getSurprizzName};