import Summary from "../..";
import { getColor } from "../../../../../data/colors";
import SummaryOption from "../../SummaryOption";

function AvailableOption(props){
    return (
        <SummaryOption
        label={props.label}
        background={props.cleared ? "#1FAE1C" : getColor("dark-blue")}
        icon="chevron_right"
        color="#FFFFFF"
        className={props.className}
        onClick={props.onClick}/>
    )
}

export default AvailableOption;