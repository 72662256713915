const gifts = [
    "pour assortir avec un accessoire que tu as déjà".toUpperCase(),
    "j’ai été obligé de tricher".toUpperCase(),
    "dans ton top 5 et pas 5eme".toUpperCase(),
    "vise bien".toUpperCase()
];

function getGift(n){
    return gifts[n];
}

export {getGift};