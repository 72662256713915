import clsx from "clsx";
import { getColor } from "../../../data/colors";

function Title(props){
    return(
        <h1 
        className={clsx(
            "font-ccs-wakatobi text-[7rem] whitespace-nowrap leading-[6rem]",
            props.className,
            props.capitalize ? "capitalize" : "uppercase"
        )}
        style={{
            color:props.color ? props.color : getColor("dark-blue")
        }}>{props.text}
        </h1>
    )
}

export default Title;