import { useEffect, useRef, useState } from "react";
import WhiteBlock from "../../../blocks/WhiteBlock";
import Title from "../../../texts/Title";
import { getQuestions } from "../../../../data/questions";
import Input from "../../../inputs/Input";
import CreamButton from "../../../buttons/CreamButton";
import gsap from "gsap/gsap-core";
import clsx from "clsx";
import Paragraph from "../../../texts/Paragraph";

function StatusCard(props){
    return (
        <div className="h-full flex flex-col pb-5 pt-5 px-3 items-center justify-between"
        style={{color: props.color}}>
            <figure className="flex justify-center">
                <img src={props.icon} width={75}/>
            </figure>
            <Title
            text={props.title}
            color={props.color}/>
            <Paragraph
            text={props.text}
            color={props.color}
            className="font-bold text-center"/>
            <CreamButton
            label={props.label}
            onClick={props.onClick}/>
        </div>
    )
}

function DisplayQuestion(props){
    return (
        <div className="flex h-full flex-col items-center py-5 px-3">
            <Title
            text="Question"
            color="#000000"
            capitalize={true}/>
            <div className="font-bold mt-0 text-center text-balance">{props.question}</div>
            <div className="grow w-full flex flex-col justify-center items-center">
                <img src="images/memes/question.png" className="w-full max-h-[110px] h-full object-contain"/>
            </div>
            <Input
            name="answer"
            value={props.userAnswer}
            placeholder="Saisir la réponse"
            onChange={(e)=>{
                props.updateAnswer(e.target.value)
            }}
            className="mx-3 max-w-[250px] mt-3 uppercase"/>
            <CreamButton
            label="Valider la réponse"
            className="mt-5"
            onClick={()=>{
                if(props.clickable){
                    props.checkAnswer()
                } 
            }}/>
        </div>
    )
}

function Question(props){
    const ref = useRef();
    const [questions,setQuestions] = useState(initializeQuestions());
    const [answer,updateAnswer] = useState("");
    const [status,updateStatus] = useState(null);
    const [visible,updateVisibility] = useState(true);
    const [clickable,updateClickable] = useState(true);
    const [question,updateQuestion] = useState(null);
    const [count,updateCount] = useState(0);

    function initializeQuestions(){
        if(props.questions){
            return [...props.questions]
        } else {
            return [...getQuestions()];
        }
    }

    useEffect(()=>{
        if(question==null){
            newQuestion(0);
        }
    },[]);
    
    function newQuestion(n){
        updateQuestion(questions[n])
    }

    function rotateAnimation(onComplete){
        updateClickable(false);
        let timeline = gsap.timeline()
        timeline.to(ref.current,{
            duration:0.25,
            rotationY: 90,
            onComplete: ()=>{
                updateVisibility(false)
            },
            ease:"none"
        })
        timeline.to(ref.current,{
            duration:0.5,
            rotationY:270,
            onComplete : ()=>{
                updateVisibility(true);
                onComplete();
            },
            ease:"none"
        })
        timeline.to(ref.current,{
            duration:0.25,
            rotationY:360
        })
        timeline.to(ref.current,{
            duration:0,
            rotationY:0,
            onComplete : ()=>{
                updateClickable(true)
            }
        })
    }

    function checkAnswer(){
        if(answer.toUpperCase()==question.answer){
            rotateAnimation(()=>{
                updateStatus("ok")
            })
        } else {
            rotateAnimation(()=>{
                updateStatus("fail")
                props.onFail();
            })
        }
    }


    return (
        <>
        <div ref={ref}>
            <WhiteBlock
            content={
                <div className={clsx(!visible && "opacity-0","h-full")}>
                    {status == null && <DisplayQuestion {...question} updateAnswer={updateAnswer} userAnswer={answer} checkAnswer={checkAnswer} clickable={clickable}/>}
                    {status=="fail" && <StatusCard
                    icon="images/Question/false.svg"
                    title="Faux"
                    text="Aïe coup dur, il fallait mieux réviser ! Tu es bloquée pour débloquer la suite, pardonne ton erreur en demandant un gage à ton maitre du jeu :)"
                    label="Recommencer"
                    onClick={()=>{
                        rotateAnimation(()=>{
                            if(clickable){
                                updateStatus(null);
                                updateAnswer("");
                            }
                        })
                    }}
                    color="#FF0000"/>}
                    {status=="ok" && <StatusCard
                    icon="images/Question/true.svg"
                    title="Vrai"
                    text="Moins bête que ce je pensais au final ehe, je vais commencer à croire que tu es intelligente ! (Non je rigole je t’ai déjà inscrit aux Marseillais)"
                    label="Continuer"
                    onClick={()=>{
                        if(clickable){
                            if(questions.length>count+1 && count<4){
                                rotateAnimation(()=>{
                                    updateStatus(null);
                                    newQuestion(count+1);
                                    updateCount(count+1);
                                    updateAnswer("");
                                })
                            } else {
                                props.onEnd();
                            }
                        }
                    }}
                    color="#1FAE1C"/>}
                </div>
            }
            height={400}/>
        </div>
        </>
    )
}

export default Question;

export {StatusCard};