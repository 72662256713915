import clsx from "clsx";
import Title from "../texts/Title";
import Paragraph from "../texts/Paragraph";
import { useGSAP } from "@gsap/react";
import gsap from "gsap/gsap-core";
import { useRef } from "react";

gsap.registerPlugin(useGSAP);

function Page(props){
    const ref = useRef();
    const transitionDuration = 0.5
    useGSAP(()=>{
        if(props.animate){
            gsap.from(ref.current,{
                duration:transitionDuration,
                xPercent:100
            })
        }
    },[])
    return (
        <div className={
            clsx(
                "w-full h-full",
                props.className
            )
        }
        ref={ref}>
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full flex flex-col items-center py-5">
                {props.title && <Title
                text={props.title}/>}

                {props.subtitle && <Paragraph
                text={props.subtitle}
                className="text-center mt-0 mb-6 px-3 font-medium"/>}

                <div className="w-full grow flex flex-col items-center justify-center pb-5">
                    {props.content}
                </div>
            </div>
        </div>
    )
}

export default Page;