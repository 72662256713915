import { useEffect, useRef, useState } from "react";
import Page from "../../Page";
import gsap from "gsap/gsap-core";
import { useGSAP } from "@gsap/react";
import React from "react";
import Title from "../../texts/Title";
import { getColor } from "../../../data/colors";
import BlueBlock from "../../blocks/BlueBlock";
import {useNavigate} from "react-router-dom";
import Footer from "../../Footer";
import CreamButton from "../../buttons/CreamButton";

gsap.registerPlugin(useGSAP);

function Presentation(props){
    return (
        <div className="w-full h-full flex flex-col justify-center">
        <BlueBlock
        title="Joyeux Annif"
        texts={[
            "Surprizz et son créateur te souhaite de passer une excellente journée. J’espère qu’aujourd’hui tu vas savoir t’amuser, rigoler, t’impatienter, aimer.",
            "Ce site va t’aider à travers la journée, sois attentive (stp il m’a pris bad longtemps à faire mdrrr)."
        ]}
        label="Découvrir ma journée"
        onClick = {props.onClick}
        />
        </div>
    )
}
function Homepage() {
    const ref = useRef();
    const navigate = useNavigate();
    const [logo, updateLogo] = useState(true);

    function handleClick(e) {
        updateLogo(false);
    }

    const transitionDuration = 0.5

    useGSAP(() => {
        if (!logo) {
            gsap.to(ref.current, {
                duration: transitionDuration,
                xPercent: -100
            })
        }
    }, [logo])

    return (
        <>
            <div className="w-full h-full overflow-x-hidden overflow-y-visible">
                <div className="relative w-full h-full" ref={ref}>
                    <Page
                        className="absolute w-full h-full"
                        content={
                            <div className="w-full h-full flex justify-center items-center flex-col">
                                <img src="images/logo.svg" alt="Logo" width={250} className=""/>
                                <CreamButton
                                label="Commencer l'aventure"
                                icon={true}
                                className="mt-8"
                                onClick={handleClick}/>
                            </div>
                        } />
                    <Page
                        className="absolute left-full w-full h-full"
                        content={
                            <>
                                <Presentation
                                onClick = {()=>{
                                    gsap.to(ref.current,{
                                        duration:transitionDuration,
                                        xPercent:-200,
                                        onComplete: ()=>{
                                            navigate("/home");
                                        }
                                    })
                                }}/>
                            </>
                        } />
                </div>
            </div>
        
        </>
    )
}

export default Homepage;