import { useEffect, useReducer, useRef, useState } from "react";
import Footer from "../Footer";
import BlueBlock from "../blocks/BlueBlock";
import gsap from "gsap";
import { useNavigate } from "react-router-dom";
import { useGSAP } from "@gsap/react";
import { useCookies } from "react-cookie";
import clsx from "clsx";

gsap.registerPlugin(useGSAP);

function Award(props){
    const ref = useRef();
    const navigate = useNavigate();
    const [cookie,setCookie,removeCookie] = useCookies(["activities","finished"]);
    const [available,setAvailable] = useState(false);
    useGSAP(()=>{
        gsap.set(ref.current,{
            xPercent: 100
        });
        if(available){
            gsap.to(ref.current,{
                duration:0.5,
                xPercent: 0
            });
        }
    },[available]);
    useEffect(()=>{
        if((cookie["activities"] && cookie["activities"][props.unlockCondition]) || cookie[props.unlockCondition]){
            setAvailable(true)
        } else {
            navigate("/home");
        }
    })
    return (
        <div className={clsx(
            "w-full h-full flex flex-col items-center justify-between",
            !props.hideImage && "pt-16"
        )} ref={ref}>
            <div className="grow w-full flex flex-col items-center justify-center relative ">
                <div className="h-fit relative mt-10 pb-5">
                    {!props.hideImage && <img src="/images/BlueBlock/gift.svg" width={250} className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-[80%]" style={{zIndex: 100}}/>}
                    <BlueBlock
                    hideAvatar
                    title={props.title}
                    subtitle={props.subtitle}
                    texts={props.texts}
                    image={props.image}
                    label={props.label}
                    onClick={()=>{
                        if(props.url){
                            window.open(props.url,"_blank");
                        } else {
                            gsap.to(ref.current,{
                                duration:0.5,
                                yPercent: 100,
                                onComplete: ()=>{
                                    navigate("/home");
                                }
                            })
                        }
                    }}/>
                </div>
            </div>

        </div>
    )
}

export default Award;