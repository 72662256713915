import clsx from "clsx";
import RadioInput from "..";
import { useState } from "react";

function SectionTitle(props){
    return (
        <h2 className={clsx(
            "bg-surprizz-cream text-surprizz-dark-blue font-extrabold w-fit p-1 text-[0.825rem]",
            props.className
        )}>{props.text}</h2>
    )
}

function RadioInputSection(props){
    const [value,setValue] = useState(null);
    return (
        <div className="w-full mb-10">
            <SectionTitle
            text={props.title}
            className="mb-5"/>
            <div>
                {props.options.map(
                    v=><RadioInput
                    name={props.name}
                    label={v}
                    className="my-2"
                    checked = {v==value}
                    onSwitch={()=>{
                        setValue(v);
                        if(props.onSwitch!=undefined){
                            props.onSwitch(v)
                        }
                    }}/>
                )}
            </div>
        </div>
    )
}

export default RadioInputSection;