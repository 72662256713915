import clsx from "clsx";
import { getColor } from "../../../../../data/colors";
import SummaryOption from "../../SummaryOption";

function ForbiddenOption(props){
    return (
        <SummaryOption
        label={props.label}
        background={getColor("grey")}
        icon="lock"
        color="#FFFFFF"
        className={clsx(
            props.className,
            "cursor-default"
        )}
/>
    )
}

export default ForbiddenOption;