import clsx from "clsx";

function Input(props){
    return(
        <input
        name={props.name}
        placeholder={props.placeholder}
        onChange={props.onChange}
        value={props.value}
        className={clsx(
            "w-full outline-none bg-surprizz-light-grey text-surprizz-dark-blue p-2 rounded-xl text-[0.825rem] focus:border-surprizz-dark-blue focus:border-2",
            props.className
        )}/>
    )
}

export default Input;