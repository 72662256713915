import Page from "../../Page";
import Question from "./Question";

function Questions(props){
    return (
        <div className="w-full h-full">
            <Page
            title={props.title}
            subtitle={props.subtitle}
            animate={true}
            content={<Question
            onFail={props.onFail}
            onEnd={props.onEnd}
            questions = {props.questions}/>}/>
        </div>
    )
}

export default Questions;