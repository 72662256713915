const sections=[
   setSection("1° J'ai plutôt envie de ...",[
    "Manger sucré",
    "Manger salé",
    "Autant l'un que l'autre",
    "Toi mon Apollon"
   ]),
   setSection("2° Je t'aime...",[
    "Vite fait, merci pour le plat",
    "Je te tolère ça va",
    "Je fais la meuf mais je suis piquée un peu en vrai",
    "Je suis amoureuse de toi"
   ])
]

function setSection(title, options){
    return {
        "title":title,
        "options":options
    }
}

function getSurveySections(firstSectionSwitch, secondSectionSwitch){
    const copiedSections = [...sections];
    copiedSections[0]["onSwitch"] = firstSectionSwitch
    copiedSections[1]["onSwitch"] = secondSectionSwitch
    return copiedSections
}

export {getSurveySections};
