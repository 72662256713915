import { useEffect, useRef, useState } from "react";
import Page from "../../Page";
import WhiteBlock from "../../blocks/WhiteBlock";
import AvailableOption from "./options/AvailableOption";
import ForbiddenOption from "./options/ForbiddenOption";
import { CookiesProvider, useCookies } from 'react-cookie'
import { getActivities } from "../../../data/activities";
import ExternalOption from "./options/ExternalOption";
import {Link, useNavigate} from "react-router-dom";
import gsap from "gsap/gsap-core";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(useGSAP);

function SummaryOptions(props){
    const navigate = useNavigate();
    const className = "my-3"
    const keyWord = "activities"
    const [cookies, setCookie, removeCookie] = useCookies([keyWord,"finished"]);
    const [activities,setActivities] = useState(getActivities());
    
    useEffect(()=>{
        if(cookies[keyWord] == undefined){
            var activitiesCookie = {}
            activities.forEach((v)=>{
                activitiesCookie[v.cookieName] = v.unrestricted
            });
            setCookie(keyWord,activitiesCookie)
        }
        if(cookies.finished){
            props.transition(()=>{
                navigate("/end");
            })
        }
    },[]);


    return(
        <CookiesProvider>
            <div className="px-5 py-3">
                {cookies[keyWord]!=null && <>
                {activities.map((v,index)=>{
                    return cookies[keyWord][v.cookieName] ? 
                    <AvailableOption
                    label={v.name}
                    className={className}
                    cleared={(index+1<activities.length && cookies[keyWord][activities[index+1].cookieName]) || cookies.finished}
                    onClick={()=>{
                        props.transition(()=>{
                            navigate(v.link)
                        })
                    }}/>
                    : <ForbiddenOption
                    label={v.name}
                    className={className}/>
                })}
                <ExternalOption
                label="Demander de l'aide"
                className = {className}
                onClick={()=>{
                    props.transition(()=>{
                        navigate("/help");
                    })
                }}/>
                </>
                }

                
            </div> 
        </CookiesProvider> 
    )
}

function SummaryList(props){
    return (
       <div className="w-full h-full flex flex-col justify-center items-center">
         <WhiteBlock
        content={<SummaryOptions
        transition={props.transition}/>}/>
       </div>
    )
}

function Summary(){
    const ref = useRef();
    
    function transition(onComplete){
        gsap.to(ref.current,{
            duration:0.5,
            xPercent:-100,
            onComplete: onComplete
        })
    }

    return(
       <div className="w-full h-full" ref={ref}>
         <Page
        title="Ma journée"
        subtitle="Bienvenue sur l’espace dédié à ta journée. Tu retrouveras ici l’ensemble des choses qui t’attendes ce 22 avril 2024."
        animate={true}
        content={<SummaryList
        transition={transition}/>}
        />
       </div>
    )
}

export default Summary;