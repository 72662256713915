import { useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(useGSAP);

function Footer(){
    const location = useLocation();
    const ref = useRef();
    const navigate = useNavigate();
    
    useGSAP(()=>{
        if(location.pathname=="/home"){
            gsap.to(ref.current.parentElement,{
                duration: 0.25,
                opacity: 1
            });
        }
    },[location]);
    return (
        <div className="bg-clip-border w-screen min-h-[4.5rem] bg-surprizz-light-blue border-solid border-surprizz-dark-blue border-t-4 relative z-30" ref={ref}>
            <figure className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-2/3" onClick={()=>{
                if(location.pathname!="/home"){
                    const timeline = gsap.timeline();
                    timeline.to(ref.current.parentElement,{
                        duration: 0.25,
                        opacity: 0,
                        onComplete: ()=>{
                            navigate("/home")
                        }
                    });
                }
            }}>
                <img src="/images/logo_footer.svg" width={75}/>
            </figure>
        </div>
    )
}

export default Footer;