import { useEffect, useRef } from "react";
import Questions from "../Questions";
import gsap from "gsap/gsap-core";
import BlueBlock from "../../blocks/BlueBlock";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

function VictoryScreen(){
    const navigate = useNavigate();
    const ref = useRef();
    return (
        <div className="w-full h-full" ref={ref}>
            <BlueBlock
            title="Bravo !!"
            texts={[
                "Félicitations, tu as accompli ta tâche et tu peux découvrir ce qui t’attend pour la suite de ta journée !"
            ]}
            label="Découvrir le repas"
            image="images/memes/homer.png"
            onClick={()=>{
                gsap.to(ref.current,{
                    duration:0.5,
                    xPercent:-100,
                    onComplete : ()=>{
                        navigate("/repas/award")
                    }
                })
            }}/>
        </div>
    )
}

function Lunch(props){
    const [cookie,setCookie,removeCookie] = useCookies(["activities"])
    const ref = useRef();
    const navigate = useNavigate();
    useEffect(()=>{
        if(cookie["activities"] && cookie["activities"]["activity"]){
            gsap.to(ref.current,{
                duration:0.5,
                xPercent:-100
            })
        } else if(!cookie["activities"]){
            navigate("/home");
        }
    },[]);
    return(
        <div className="w-full h-full overflow-x-hidden overflow-y-visible">
            <div className="w-full h-full flex" ref={ref}>
                <div className="min-w-full h-full">
                    <Questions
                    title="Repas"
                    subtitle="Pour savoir où nous allons manger, répond juste à l’ensemble de ces questions. Attention, une mauvaise réponse et tu ne sauras pas jusqu’à la destination !"
                    onFail={props.lock}
                    onEnd={()=>{
                        setCookie("activities",{...cookie["activities"], "activity":true})
                        gsap.to(ref.current,{
                            duration:0.5,
                            xPercent:-100
                        })
                    }}/>
                </div>
                <div className="min-w-full h-full">
                    <VictoryScreen/>
                </div>
            </div>
        </div>
    )
}

export default Lunch;