import { useEffect, useRef, useState } from "react";
import Page from "../../Page";
import Input from "../../inputs/Input";
import CreamButton from "../../buttons/CreamButton";
import { getSurprizzName } from "../../../data/activities";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(useGSAP);

function InstagramContent(props) {
    const [answer, updateAnswer] = useState("");

    function checkAnswer(){
        if(answer.toUpperCase() != getSurprizzName()){
            props.onFail();
        } else {
            props.onWin();
        }
    }
    return (
        <div className="max-w-[190px] flex flex-col items-center">
            <a href="https://www.instagram.com/surprizz_off?igsh=MTVlMm40OXFkcWFhOQ%3D%3D&utm_source=qr" target="_blank">
                <img src="/images/instagram.png" className="w-full" />
            </a>
            <label className="text-[0.825rem] font-bold text-surprizz-dark-blue mt-5">Note ta réponse ci-dessous</label>
            <Input
                name="answer"
                value={answer}
                placeholder="Saisir la réponse"
                onChange={(e) => {
                    updateAnswer(e.target.value.toUpperCase())
                }}
                className="mx-3 w-full uppercase mt-1 mb-5" />
            <CreamButton
            label="Valider la réponse"
            onClick={checkAnswer}/>    

        </div>
    )
}

function Surprizz(props) {
    const ref = useRef();
    const navigate = useNavigate();
    const [cookie,setCookie,removeCookie] = useCookies(["activities"]);
    useEffect(()=>{
        if(!cookie["activities"] || !cookie["activities"]["surprizz"]){
            navigate("/home");
        }
    },[]);
    useGSAP(()=>{
        if(cookie.activities && cookie.activities.prepare){
            gsap.set(ref.current,{
                xPercent:100
            }); 
            gsap.to(ref.current,{
                duration:0.5,
                xPercent: -100,
                onComplete : ()=>{
                    navigate("/surprizz/award")
                }
            })
        } else {
            gsap.from(ref.current,{
                duration:0.5,
                xPercent:100
            })
        }
    },[])
    return (
        <div ref={ref}>
            <Page
                title="Surprizz"
                subtitle="Pour cette surprise, tu vas devoir te rendre sur le compte instagram suivant en cliquant sur la card en bas, tout le reste se passe là-bas !"
                content={<InstagramContent {...props} onWin={()=>{
                    setCookie("activities",{
                        ...cookie.activities,
                        "prepare":true
                    });
                    gsap.to(ref.current,{
                        duration:0.5,
                        xPercent: -100,
                        onComplete : ()=>{
                            navigate("/surprizz/award")
                        }
                    })

                }}/>} />
        </div>
    )
}

export default Surprizz;

