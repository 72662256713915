import clsx from "clsx";
import { useRef } from "react";

function SummaryOption(props){
    const ref = useRef();
    return (
        <button
        style={{
            color : props.color,
            background: props.background
        }}
        className={clsx(
            "w-full flex justify-between font-bold items-center text-[0.75rem] px-6 py-3 rounded-2xl transition-all",
            props.className
        )}
        onClick={props.onClick && props.onClick}
        ref={ref}
        onTouchStart={()=>{
            if(props.onClick){
                ref.current.style.backgroundColor = props.color
                ref.current.style.color = props.background
            }
        }}
        onTouchEnd={()=>{
            if(props.onClick){
                ref.current.style.backgroundColor = props.background
                ref.current.style.color = props.color
            }
        }}>
            <span>{props.label}</span>
            {props.icon && <img className={clsx(
                props.invert && "invert"
            )} src={`/images/icons/${props.icon}.svg`} alt="icon"/>}
        </button>
    )
}

export default SummaryOption;