import { getColor } from "../../../data/colors";
import Button from "../Button";

function CreamButton(props){
    return (
        <Button
        label={props.label}
        background={getColor("cream")}
        color={getColor("dark-blue")}
        icon={props.icon}
        className={props.className}
        onClick={props.onClick}/>
    )
}

export default CreamButton;