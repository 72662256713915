import { useCookies } from "react-cookie";
import Page from "../../Page";
import WhiteBlock from "../../blocks/WhiteBlock";
import { useEffect, useRef, useState } from "react";
import ForbiddenOption from "../Summary/options/ForbiddenOption";
import AvailableOption from "../Summary/options/AvailableOption";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { getRandomInt } from "../../../data/questions";
import { useNavigate } from "react-router-dom";
import BlueBlock from "../../blocks/BlueBlock";
import { getGift } from "../../../data/gifts";

gsap.registerPlugin(useGSAP);

function getGifts(n){
    const res = [];
    for(let i=1;i<=4;i++){
        res.push({
            "id": i,
            "name":`Cadeau n°${i}`,
            "locked":n<i,
            "clue" : getGift(i-1)
        })
    }
    return res;
};

function GiftImage(props){
    const ref = useRef();
    var clickable = props.clickable
    var key = props.key
    useGSAP(()=>{
        const duration = 0.5;
        const topValue = "33%";
        const leftValue = "33%";
        const timeline = gsap.timeline({
            onComplete : ()=>{
                gsap.to(ref.current,{
                    duration: duration,
                    top: props.y,
                    left: props.x
                })
            },
        });
        timeline.to(ref.current,{
            duration: duration,
            top: topValue,
            left: leftValue
        }).to(ref.current,{
            duration: duration,
            top: props.y,
            left : props.x
        })
    },[key])
    return (
        <div className="absolute w-1/3 aspect-square flex justify-center items-center" style={{
            left: props.x,
            top: props.y
        }}
        ref={ref}
        onClick={()=>{
            if(clickable){
                props.setClickable(false);
                if(props.real){
                    let firstTimeline = gsap.timeline();
                    firstTimeline.to(ref.current,{
                        zIndex: 50,
                        duration:0.5,
                        scale: 3,
                        top: "50%",
                        left: "50%",
                        xPercent: -50,
                        yPercent: -50
                    });
                    firstTimeline.to(ref.current,{
                        duration: 0.5,
                        y: -window.screen.availHeight,
                        opacity: 0,
                        onComplete : ()=>{
                            props.onWin();
                        }
                    })
                } else {
                    let timeline = gsap.timeline({
                        repeat: 1,
                        onComplete : ()=>{
                            gsap.to(ref.current,{
                                duration: 0.1,
                                x: 0,
                                y: 0,
                                onComplete : ()=>{
                                    props.setClickable(true);
                                    props.onFail();
                                }
                            })
                        }
                    })
                    timeline.to(ref.current,{
                        duration: 0.1,
                        x: -20
                    })
                    timeline.to(ref.current,{
                        duration: 0.1,
                        x: 20,
                    })
                }
            }
        }}>
            <img src="images/gift.svg" className="w-3/4 aspect-square object-contain"/>
        </div>
    )
}

function getGiftImages(containerWidth){
    let res = [];
    for(let i = 0; i<3; i++){
        for(let j = 0; j < 3; j++){
            res.push({
                "key" : Math.random(),
                x: (containerWidth/3)*i,
                y: (containerWidth/3)*j
            })
        }
    }
    res[getRandomInt(res.length-1)]["real"] = true
    return res;
}

function GiftGame(props){
    const containerWidth = 300
    const [giftImages, setGiftImages] = useState(getGiftImages(containerWidth));
    const [clickable,setClickable] = useState(true);
    const ref = useRef();
    return (
        <div className="aspect-square relative" style={{
            width: containerWidth
        }}
        ref={ref}>
            {giftImages.map(
                v=><GiftImage {...v} onFail={()=>{
                    setGiftImages(getGiftImages(containerWidth))
                }} onWin={props.onWin} clickable={clickable} setClickable={setClickable}/>
            )}
        </div>
    )
}

function GiftList(props){
    var cookie = props.cookie
    const [gifts,setGifts] = useState(getGifts(cookie.gifts));
    const className = "my-3"
    useEffect(()=>{
        if(!props.cookie.gifts){
            props.setCookie("gifts",1);
            setGifts(getGifts(1));
        }
    },[]);

    useEffect(()=>{
        setGifts(getGifts(cookie.gifts))
    },[cookie]);

    return (
        <WhiteBlock
        content={
            <div className="px-5 py-3 w-full">
                {gifts!=null && gifts.map(
                    (v,index)=><>
                    {v.locked ? <ForbiddenOption
                    label={v.name}
                    className={className}/> : <AvailableOption
                    label={v.name}
                    className={className}
                    onClick={()=>{
                        if(cookie.gifts-1<=index){
                            props.setGift(v);
                        }
                    }}
                    cleared={cookie.gifts-1>index}/>}
                    </>
                )}
            </div>
        }/>
    )
}

function GiftAppear(props){
    const ref = useRef();
    const block = useRef();
    useGSAP(()=>{
        const timeline = gsap.timeline();
        timeline.from(ref.current,{
            duration:0.5,
            opacity: 0,
            onComplete : props.onFirstComplete
        }).from(block.current,{
            duration: 0.5,
            yPercent: 100
        })
    },[])
    return (
        <div className="fixed top-0 left-0 w-screen min-h-dvh bg-white z-50" ref={ref}>
            <div className="w-full h-dvh flex flex-col items-center justify-center" ref={block}>
                <BlueBlock
                title="Bravo !!"
                image="/images/memes/santa.png"
                texts={[`Félicitations, tu as trouvé ton cadeau ! L’indice est le suivant : ${props.clue}`]}
                label="Continuer"
                onClick={()=>{
                    ref.current.parentElement.parentElement.scroll({
                        top: 0,
                        behavior: 'smooth',
                    });
                    const timeline = gsap.timeline();
                    timeline.to(block.current,{
                        duration: 0.5,
                        yPercent: -100
                    }).to(ref.current,{
                        duration:0.5,
                        opacity: 0,
                        onComplete : props.onSecondComplete
                    })
                }}/>
            </div>
        </div>
    )
}

function Gifts(props){
    const [cookie,setCookie,removeCookie] = useCookies(["gifts","activities"]);
    const [gift,setGift] = useState(null);
    const [seeGift,setSeeGift] = useState(false);
    const ref = useRef();
    const pageContainer = useRef();
    const navigate = useNavigate();
    useGSAP(()=>{
        if(gift){
            gsap.to(ref.current,{
                duration:0.5,
                xPercent:-100
            })
        } else {
            gsap.to(ref.current,{
                duration:0.5,
                xPercent: 0
            });
            if(cookie.gifts>=5){
                gsap.to(pageContainer.current,{
                    duration:0.5,
                    xPercent:-100,
                    onComplete : ()=>{
                        setCookie("finished",true);
                        navigate("/end");
                    }
                })
            }
        }
    },[gift]);
    useGSAP(()=>{
        gsap.from(pageContainer.current,{
            duration:0.5,
            xPercent:100
        })
    },[]);
    useEffect(()=>{
        if(!cookie.activities || !cookie.activities.gifts){
            navigate("/home");
        }
    },[]);
    return (
        <div ref={pageContainer} className="w-full h-full relative">
            <Page
            title="Cadeaux"
            subtitle="Pour découvrir l’un de tes vrais cadeaux, tu vas devoir découvrir le bon ici ! Attention tu n’as qu’une chance par partie."
            content={
                <div className="w-full h-full flex" ref={ref}>
                    <div className="min-w-full flex flex-col items-center">
                        <GiftList
                        setGift={setGift}
                        cookie={cookie}
                        setCookie={setCookie}/>
                    </div>
                    <div className="min-w-full h-full flex flex-col items-center justify-center pb-32">
                        {gift!=null && <GiftGame onWin={()=>{
                            setSeeGift(true);
                            props.setFooter(false);
                        }}/>}
                    </div>
                </div>
            }/>
            {seeGift && <GiftAppear onSecondComplete={()=>{
                setSeeGift(false);
                if(gift.id + 1 > cookie.gifts){
                    setCookie("gifts",gift.id + 1);
                }
                props.setFooter(true);
                gsap.to(ref.current,{
                    duration:0.5,
                    xPercent: 0,
                    onComplete : ()=>{
                        setGift(null);
                    }
                })
            }}
            clue={gift.clue}/>}
        </div>
    )
}

export default Gifts;