import Page from "../../Page";
import WhiteBlock from "../../blocks/WhiteBlock";
import Title from "../../texts/Title";
import Input from "../../inputs/Input";
import { useEffect, useRef, useState } from "react";
import CreamButton from "../../buttons/CreamButton";
import gsap from "gsap";
import { getActivityName } from "../../../data/activities";
import clsx from "clsx";
import { StatusCard } from "../Questions/Question";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(useGSAP);

function MainContent(props) {
    return (
        <div className="flex flex-col items-center py-3 h-full justify-center">
            <div className="mt-3">
                <iframe className="aspect-video h-auto" width="250" src="https://www.youtube.com/embed/_rgvOWROnws?si=qf9hzZ5g_-eWPj3f" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <label className="font-bold text-[0.825rem] mt-5 mb-1">Note la réponse de l'activité</label>
            <Input
                name="answer"
                value={props.userAnswer}
                placeholder="Saisir la réponse"
                onChange={(e) => {
                    props.updateAnswer(e.target.value.toUpperCase())
                }}
                className="mx-3 max-w-[250px] uppercase mb-8" />
            <CreamButton
                label="Valider la réponse" 
                onClick = {props.onClick}/>
        </div>
    )
}
function Activity(props) {
    const ref = useRef();
    const [answer, updateAnswer] = useState("");
    const [clickable, updateClickable] = useState(true);
    const [visibility, updateVisibility] = useState(true);
    const [status, updateStatus] = useState(null);
    const [cookie,setCookie,removeCookie] = useCookies(["activities"]);
    const navigate = useNavigate();
    const page = useRef();

    function rotateAnimation(onComplete) {
        updateClickable(false);
        let timeline = gsap.timeline()
        timeline.to(ref.current, {
            duration: 0.25,
            rotationY: 90,
            onComplete: () => {
                updateVisibility(false)
            },
            ease: "none"
        })
        timeline.to(ref.current, {
            duration: 0.5,
            rotationY: 270,
            onComplete: () => {
                updateVisibility(true);
                onComplete();
            },
            ease: "none"
        })
        timeline.to(ref.current, {
            duration: 0.25,
            rotationY: 360
        })
        timeline.to(ref.current, {
            duration: 0,
            rotationY: 0,
            onComplete: () => {
                updateClickable(true)
            }
        })
    }

    function checkAnswer() {
        if (answer.toUpperCase() == getActivityName()) {
            rotateAnimation(() => {
                updateStatus("ok")
            })
        } else {
            rotateAnimation(() => {
                updateStatus("fail")
                props.onFail();
            })
        }
    }

    useGSAP(()=>{
        if(cookie["activities"] && cookie["activities"]["surprizz"]){
            gsap.to(page.current,{
                duration:0.5,
                xPercent: -100,
                onComplete : ()=>{
                    navigate("/activite/award")
                }
            })
        }
    }, []);

    useEffect(()=>{
        if(!cookie["activities"] || !cookie["activities"]["activity"]){
            navigate("/home");
        }
    },[]);


    return (
        <div className="w-full h-full" ref={page}>
            <Page
            title="Activité"
            subtitle="Manger c’est bien mais une activité c’est quand même plus original enfin, surtout les miennes ;). Sois attentive à la vidéo suivante pour tenter de deviner ce que l’on fait ! Le temps que tu ne trouves pas, je ne dirai rien mais en route il ne faut pas être en retard :)"
            animate={true}
            content={
                <div ref={ref}>
                    <WhiteBlock
                        content={
                            <div className={clsx(!visibility && "opacity-0", "h-full")}>
                                {status == null && <MainContent {...props} updateAnswer={updateAnswer} userAnswer={answer} onClick ={()=>{
                                    if(clickable){
                                        checkAnswer();
                                    }
                                }}/>}
                                {status == "fail" && <StatusCard
                                    icon="images/Question/false.svg"
                                    title="Faux"
                                    text="Aïe coup dur, il fallait mieux réviser ! Tu es bloquée pour débloquer la suite, pardonne ton erreur en demandant un gage à ton maitre du jeu :)"
                                    label="Recommencer"
                                    onClick={() => {
                                        rotateAnimation(() => {
                                            if (clickable) {
                                                updateStatus(null);
                                                updateAnswer("");
                                            }
                                        })
                                    }}
                                    color="#FF0000" />}
                                {status == "ok" && <StatusCard
                                    icon="images/Question/true.svg"
                                    title="Vrai"
                                    text="Moins bête que ce je pensais au final ehe, je vais commencer à croire que tu es intelligente ! (Non je rigole je t’ai déjà inscrit aux Marseillais)"
                                    label="Continuer"
                                    onClick={() => {
                                        if (clickable) {
                                            setCookie("activities",{...cookie.activities, "surprizz":true});
                                            gsap.to(page.current,{
                                                duration:0.5,
                                                xPercent:-100,
                                                onComplete: ()=>{
                                                    navigate("/activite/award");
                                                }
                                            })
                                        }
                                    }}
                                    color="#1FAE1C" />}
                            </div>
                        }
                        height={350} />
                </div>
            } />
        </div>
    )
}

export default Activity;