function setQuestion(question, answer){
    return {
        "question":question,
        "answer":answer.toUpperCase()
    }
}

const questions = [
    setQuestion("Quel est mon club de football préféré ?", "RC LENS"),
    setQuestion("Quel est mon signe astrologique ?","LION"),
    setQuestion("Quel est mon rêve le plus grand ?","VOYAGE"),
    setQuestion("Quelle est ma voiture de rêve ?","BENTLEY"),
    setQuestion("Quel est mon plus grand regret ?","FILLEULE"),
    setQuestion("Quel est ma plus grande peur ?","TRAHISON"),
    setQuestion("Quel est mon plat préféré (3 mots) ?","PÂTES AU PESTO"),
    setQuestion("Quelle est ma couleur préférée ?","VERT"),
    setQuestion("Quel est mon artiste POP favori ?","ED SHEERAN"),
    setQuestion("Quel est mon pire défaut ?","PONCTUALITÉ")
]

function getQuestions(){
    return questions.sort(function(a, b) {
        return getRandomInt(questions.length) - getRandomInt(questions.length);
    });
}

function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

export {getQuestions, getRandomInt};